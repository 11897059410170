import { ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormGroup, Validators, FormControl, AbstractControl, ValidationErrors } from '@angular/forms';
import { Router } from "@angular/router";
import { BaseService } from 'app/@core/services/base.service';
import { NgxSpinnerService } from "ngx-spinner";
import { StorageService } from "app/@core/services/storage.service";
import { FormValidatorService } from 'app/@core/services/formValidator.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { environment } from 'environments/environment.dev';


declare var $: any;

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})

export class LoginPageComponent {

  loginFormSubmitted = false;
  isLoginFailed = false;
  passwordBool: boolean = false;
  isLoading = false;
  userName: string = "";

  loginForm = new UntypedFormGroup({
    loginEmail: new FormControl('', [Validators.required, Validators.email, this.formValidatorService.blankSpacesValidator]),
    loginPassword: new FormControl('', [Validators.required]),
    rememberMe: new FormControl(true),
  });
  ezportal = environment.ezportal;


  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    public baseService: BaseService,
    public storageService: StorageService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private formValidatorService: FormValidatorService,
    private location: Location
  ) {
    if (localStorage.getItem('authToken')) {
      const isMultipleSubscription = JSON.parse(localStorage.getItem("isMultipleSubscription"));
      if(isMultipleSubscription) {
      this.router.navigate(['/modules']);
    }
    }
  }

  get lf() {
    return this.loginForm.controls;
  }

  private constructUrlWithParams(route: string, params: any): string {
    const queryString = Object.keys(params).map(key => key + '=' + encodeURIComponent(params[key])).join('&');
    return route + '?' + queryString;
  }

  // On submit button click
  onSubmit() {
    this.loginFormSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.isLoading = true;
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    this.baseService.postRequest('user/login', this.loginForm.value)
      .subscribe(
        response => {
        this.spinner.hide();
        this.isLoading = false;
        let data = response.data
        let subscription: any[] = []
        let name: string = "";
        let email: string = "";
        let userId: string = "";
        let companyUserId: string = "";
        let token: string = "";
        if (response.statusCode == 200 ) {

          localStorage.setItem("userType", response.data.company.userType)

          if(response.data.company.userType === "Company"){
            userId = data.company.companyId;
            name = data.company.companyName;
            email = data.company.email;
            token = data.token;
            subscription = data.company.subscription;
            localStorage.setItem("userId", data.company.companyId)
            localStorage.setItem("isMultipleSubscription", data.company.isMultipleSubscription);
            localStorage.setItem("companyID", data.company.companyId)
            localStorage.setItem('authToken', data.token);
            this.storageService.setCompanyName(data.company.companyName);
            this.storageService.setUserEmail(data.company.email);
          }else if(response.data.company.userType === "Company User"){
            companyUserId = response.data.company.companyUserId;
            userId = data.company.companyId;
            name = data.company.companyName;
            this.userName = data.company.name;
            email = data.company.email;
            token = data.token;
            subscription = data.company.subscription;
            localStorage.setItem('authToken', data.token);
            localStorage.setItem('userType', response.data.company.userType);
            this.storageService.setUserName(data.company.name);
            this.storageService.setCompanyName(data.company.companyName);
            this.storageService.setUserEmail(data.company.email);
            localStorage.setItem("companyUserId", data.company.companyUserId);
            localStorage.setItem("companyID", data.company.companyId)
            localStorage.setItem("isMultipleSubscription", data.company.isMultipleSubscription);
            // this.router.navigate(['/modules']);
          }else if(response.data.company.userType === "Company"){
            // subscription = data.company.subscription;
            // localStorage.setItem("companyID", data.company.companyId)
            // localStorage.setItem('authToken', data.token);
            // this.storageService.setUserName(data.company.companyName);
            // this.storageService.setUserEmail(data.company.email);
          }

          if(data.company.isMultipleSubscription && subscription.length>1) {
            localStorage.setItem("subscription", JSON.stringify(subscription));
            // this.router.navigateByUrl("/product")
            this.router.navigate(['/modules']);
          }else{
            localStorage.removeItem("subscription")
            const subscriptionId = subscription[0]?.subscriptionId;
            const moduleId = subscription[0]?.moduleId;
            localStorage.setItem("moduleId", moduleId)
            // const route = 'http://localhost:63844/tracked-assets';
            const route = subscription[0]?.url;
            // const route = 'http://localhost:53116/tracked-assets';
            // const route = 'http://localhost:49823/tracked-assets';
          const queryParams = {
            companyId: userId,
            companyUserId: companyUserId,
            moduleId: moduleId,
            userEmail: email,
            companyName: name,
            userName: this.userName,
            authToken: token,
            subscriptionId: subscriptionId,
            isMultipleSubscription: "",
            userType: response.data.company.userType
          };
          const urlWithParams = this.constructUrlWithParams(route, queryParams);
           window.location.href = urlWithParams;

          //window.open(urlWithParams, '_blank');
          }
          
          
          
          // localStorage.setItem('authToken', response.message);
          // localStorage.setItem('userId', response.userId);
          // this.storageService.setCompanyID(response.companyId);
          // this.storageService.setCompanyName(response.companyName);
          // this.storageService.setUserName(response.userName);
          // this.storageService.setUserEmail(response.email);
          // this.storageService.setRoles(response.roleName);

          // if (response.isEnableAssetPool) {
          //   this.storageService.setEnableAssetPool(response.isEnableAssetPool);
          // }
          // this.router.navigate(['/modules']);
        } else {
          this.toastr.info(response.message);
          // this.toastr.error(`Email or Password is invalid`, 'Opps!');
        }
        this.cdr.markForCheck();
      }, error => {
        this.isLoginFailed = true;
        this.spinner.hide();
        this.isLoading = false;
        this.toastr.error(`${error.error}`)
      });
  }

  changePasswordView() {
    if ($("#txtpassword").attr("type") === "password") {
      $("#txtpassword").attr("type", "text");
      this.passwordBool = false;
    } else {
      $("#txtpassword").attr("type", "password");
      this.passwordBool = true;
    }
  }

  removeWhiteSpaces(e: Event) {
    let trimmedText = this.formValidatorService.removeWhiteSpaces(e);
    this.loginForm.get('loginEmail').setValue(trimmedText);
  }

  back() {
    if (localStorage.getItem('Portal') == 'Angular') {
      this.router.navigate(['/']);
    } else {
      window.location.href = this.ezportal;
    }
  }

}

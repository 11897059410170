import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isLogout = new URLSearchParams(window.location.search).get('isLogout');
  const resetToDefault = new URLSearchParams(window.location.search).get('resetToDefault');
  if(isLogout || resetToDefault) {
    localStorage.clear();
  }

    
    let isAuth = this.authService.isAuthenticated();
    if (!isAuth) {
      this.router.navigate(['/login']);
    }
    else {
      return true;
    }
  }
}

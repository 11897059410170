import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterDataService {

  private filteredData: any[] = [];
  private sortedData: any = {};
  private showFilterRow: boolean = false;
  
  constructor() { }

  setFilteredData(data: any[]): void {
    this.filteredData = data;
  }

  getFilteredData(): any[] {
    return this.filteredData;
  }

  setSortData(data) {
    this.sortedData = data;
  }

  getSortedData() {
    return this.sortedData
  }

  setShowFilterRow(isShow) {
    this.showFilterRow = isShow;
  }

  getShowFilterRow() {
    return this.showFilterRow;
  }
}

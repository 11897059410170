import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, Output, OnDestroy, ViewChild, AfterContentInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BaseService } from 'app/@core/services/base.service';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, takeUntil, tap, filter, takeLast } from 'rxjs/operators';

@Component({
  selector: 'ngx-column-filter',
  styleUrls: ['./column-filter.component.scss'],
  templateUrl: './column-filter.component.html',
})
export class ColumnFilterComponent implements AfterViewInit, OnDestroy {
  @Output() changed = new EventEmitter();
  @Input() column;
  private searchTimer;
  options: string[] = [];
  isLoading = false;
  nullVall = null;
  textSearch: string = '';
  isChnagedTextFilter = false;
  textSearchControl = new FormControl('');
  matMultiSelectControl = new FormControl('');
  public filterControl = new FormControl();
  private unsubscribe$ = new Subject()
  filteredOptions$: Observable<any>;
  placeholderVal: true
  constructor(
    private http: HttpClient,
    private baseService: BaseService,
    private route: ActivatedRoute
  ) {
    this.filteredOptions$ = this.filterControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(val => {
          this.isLoading = true;
          console.log(typeof val)
          const optionValue = typeof val === 'object' ? val : {
            value: val
          };
          return this.filterOptions(optionValue)
        })
      );
  }

  ngAfterViewInit() {
    const tableSettingKey = localStorage.getItem('tableSettingKey');
    let filteredData: any = localStorage.getItem(tableSettingKey);
    let parseData = filteredData ? JSON.parse(filteredData) : "";
    const filterarray = parseData?.filters;
    if (filterarray) {
      const fileddata = filterarray.find((filter: any) => filter.filterKey == this.column.field);
  
      if (fileddata && this.column.filterConfig.filterType == 'text') {
        this.textSearchControl.setValue(fileddata.filterValue);
      } else if (fileddata && this.column.filterConfig.filterType == 'select2') {
        this.matMultiSelectControl.setValue(fileddata.filterValue);
      } else if (fileddata && this.column.filterConfig.filterType == 'select') {
        this.matMultiSelectControl.setValue(fileddata.filterValue);
      }
    }

    // const textSearch = this.route.snapshot.params[this.column?.field] || '';
    // this.route.queryParams.subscribe(param => {

    //   // console.log("param inside colum>>>>>>>>>>>>>>>>>>", param)
    //   const textSearch = param[this.column?.field] || '';
    //   if (textSearch) {
    //     this.textSearchControl.setValue(textSearch);
    //   }else if(this.column.filterConfig.filterType === 'select'){

    //   }else{
    //     this.textSearchControl.setValue("");
    //   }
    // })

    // this.textSearchControl.setValue(textSearch);
    if (this.column?.filterConfig?.filterType == 'select2') {
      this.column['$filteredOptions$'] = this.fetchOptions(this.column.filterConfig.URL);
    }
    else {

    }
  }

  applyTextFilter(event: KeyboardEvent, column: any = {}) {
    const searchValue = (event.target as HTMLInputElement).value.trim();
    const trimValue = searchValue.trim();
    const filterMinSearch = +column.filterMinSearch;
    const textLength = trimValue.length;
    if (
      !isNaN(+trimValue) ||
      filterMinSearch >= textLength ||
      textLength >= 3 ||
      (trimValue.length === 0 && this.isChnagedTextFilter)
    ) {
      clearTimeout(this.searchTimer);
      const search = (event.target as HTMLInputElement).value.trim();
      const sendValue = search.trim();
      this.searchTimer = setTimeout(() => {
        const data = {
          filterKey: column.field,
          filterValue: sendValue
        };
        this.changed.emit(data);
      }, 1000);
    }
  }

  onKeyUp(event: KeyboardEvent, column) {
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      const searchValue = (event.target as HTMLInputElement).value.trim();
      const data = {
        filterKey: column.field,
        filterValue: searchValue // Use the latest search value in the API call
      };
      this.changed.emit(data);
    }, 1000);
  }


  applyFilter(event) {
    const data = {
      filterKey: this.column.field,
      filterValue: event
    }
    this.changed.emit(data);
  }

  applySelect2Filter(event, column) {
    const data = {
      filterKey: this.column.field,
      filterValue: event
    }
    this.changed.emit(data);
  }

  fetchOptions(URL: string): Observable<[]> {
    return this.baseService.filterSelect2Options.length ?
      of(this.baseService.filterSelect2Options) :
      this.baseService.getRequest(URL).pipe(
        takeUntil(this.unsubscribe$),
        map((response: any) => this.mapSelectResponse(response)),
        tap(data => this.baseService.filterSelect2Options = data)
      )
  }

  mapSelectResponse(options) {
    return options.map(r => {
      return {
        id: r[this.column.filterConfig.valueKeyName] || r.id,
        value: r[this.column.filterConfig.displayKeyName]
      }
    })
  }


  nrecod = false;
  filterOptions(searchOption): Observable<any[]> {
    // call the service which makes the http-request
    return this.getOptionsData()
      .pipe(
        map((response: any) => {
          this.isLoading = false;
          this.nrecod = false;
          const options = searchOption && searchOption.value ?
            response.filter(option => option.value.toLowerCase().indexOf(searchOption.value.toLowerCase()) === 0)
            :
            response;
          this.nrecod = options.length;
          return options
        })
      )
  }

  opts = [];
  getOptionsData() {
    return this.opts.length ?
      of(this.opts) :
      this.baseService.getRequest(this.column.filterConfig.URL)
        .pipe(
          map((response: any) => this.mapSelectResponse(response)),
          tap(data => this.opts = data)
        )
  }

  displayFn(option) {
    return option && option.value || '';
  }

  ngOnDestroy() {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  selectionChange(event) {
    const data = {
      filterKey: this.column.field,
      filterValue: event.value.map(r => r.id)
    }
    this.changed.emit(data);
  }

  autoCompleteSelected(event) {
    const data = {
      filterKey: this.column.field,
      filterValue: this.filterControl.value.id
    }
    this.changed.emit(data);
  }

  onPanelClose() {
    const valuess: any = this.matMultiSelectControl.value || [];
    const data = {
      filterKey: this.column.field,
      filterValue: valuess.length ? valuess.map(r => r.id) : []
    }
    // this.changed.emit(data);
  }
}

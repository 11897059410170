<!-- <div class="container-fluid fixed-background">
  <div class="row full-height-vh m-0" style="overflow: auto;">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <div class="card overflow-hidden">
        <div class="card-content">
          <div class="card-body p-0">
            <div class="row m-0">
              <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center logo-img p-3">
                <img src="assets/img/gallery/logo-visibility.png" alt="" class="img-fluid" width="300" height="230">
              </div>
              <div class="col-lg-6 col-12 px-4 py-3">
                <h1 class="mb-2 text-center text-success">LOGIN</h1>
                <ngb-alert type="light-danger" class="mb-2" *ngIf="isLoginFailed" (close)="isLoginFailed = false">
                  <p class="mb-0 text-white">Email or Password is invalid</p>
                </ngb-alert>
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                  <div class="form-group position-relative has-icon-left">
                    <input type="text" (input)="removeWhiteSpaces($event)" formControlName="loginEmail" class="form-control" placeholder="Email"
                      [ngClass]="{ 'is-invalid': loginFormSubmitted && lf.loginEmail.invalid }" required>
                    <div class="form-control-position">
                      <i class="text-success ft-user"></i>
                    </div>

                    <div *ngIf="loginFormSubmitted && (lf.loginEmail?.errors?.required)"
                      class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> This is
                      required
                    </div>
                    <div *ngIf="loginFormSubmitted && (lf.loginEmail?.errors?.email)"
                      class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i>
                      Invalid email address
                    </div>
                  </div>

                  <div class="form-group position-relative has-icon-left">
                    <input id="txtpassword" type="password" formControlName="loginPassword" class="form-control"                      
                      placeholder="Password"
                      [ngClass]="{ 'is-invalid': loginFormSubmitted && lf.loginPassword?.invalid }" required>
                    <div class="form-control-position">
                      <i class="text-success ft-lock"></i>
                    </div>
                    <div class="form-control-position" (click)="changePasswordView()"
                      style="right: 0; cursor: pointer;">
                      <i [ngClass]="passwordBool === true ? 'text-success fa fa-eye': 'text-success fa fa-eye-slash'"
                        aria-hidden="true"></i>
                    </div>

                    <div *ngIf="loginFormSubmitted && (lf.loginPassword?.invalid || lf.loginPassword?.errors?.required)"
                      class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> This is required
                    </div>
                  </div>
                  <div class="d-sm-flex justify-content-between mb-3 font-small-2">
                    <div class="remember-me mb-2 mb-sm-0">
                      <div class="checkbox checkbox-success">
                        <input type="checkbox" formControlName="rememberMe" class="form-control" id="rememberMe">
                        <label for="rememberMe"><span class="font-small-2 mb-3 font-weight-normal">Remember
                            Me</span></label>
                      </div>
                    </div>
                    <a class="text-success" routerLink="/forgot-password">Forgot Password?</a>
                  </div>
                  <button type="submit" class="btn btn-success w-100" 
                    [disabled]="isLoading">Login</button>
                </form>
                <hr>
                <a class="text-success c-pointer align-contact" href="https://www.e4score.com/contact-us/" target="_blank">Contact us</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- <div class="container-fluid fixed-background">
  <div class="row full-height-vh m-0" style="overflow: auto;">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
          <img src="assets/img/gallery/E4score.svg" alt=""  width="30%" height="100%">
      </div>
      <div class="row border mt-5 m-auto ml-3 d-flex justify-content-center">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 w-50 text-left my-3 p-0">
              <img src="assets/img/gallery/EZTrack.png" alt="" class="img-fluid" width="50%" height="100%">
              <a href="https://go.e4score.com/eztrack-comp" target="_blank">
              <i class="text-white ft-info"></i>
              </a>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 w-50 text-center mx-1">
              <h1 class="text-white font-weight-bold w-100 text-center mb-3">Login</h1>
          </div>


      </div>
  </div>
</div> -->

<div class="app flex-row align-items-center animated fadeIn fixed-background">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-4">
        <div class="card-group">
          <a href="https://www.gete4score.com/" style="display: flex; justify-content: center;">
            <img src="assets/img/gallery/E4score color change.svg" alt="" class="login-logo w-100">
          </a>
          <div class="p-4 text-left landingCard card">
            <span class=" mb-3">
              <img src="assets/img/gallery/EZTrack.png" alt="" class="img-fluid" width="50%" height="100%">
              <a href="https://e4score.com/solutions/eztrack" target="_blank">
                <i class="text-white ft-info"></i>
              </a>
            </span>
            <h2 class="text-center text-white font-weight-bold" style="font-size: 28px; font-weight: 400;">LOGIN
            </h2>
            <div class="text-center position-relative card-body pb-0">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-0 mx-1">
                  <ngb-alert type="light-danger" class="mb-2" *ngIf="isLoginFailed" (close)="isLoginFailed = false">
                    <p class="mb-0 text-white">Email or Password is invalid</p>
                  </ngb-alert>
                  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="form-group position-relative has-icon-left">
                      <input type="text" (input)="removeWhiteSpaces($event)" formControlName="loginEmail"
                        class="form-control text-white" placeholder="Email"
                        [ngClass]="{ 'is-invalid': loginFormSubmitted && lf.loginEmail.invalid }" required>
                      <div class="form-control-position">
                        <i class="text-white ft-user"></i>
                      </div>
                      <div *ngIf="loginFormSubmitted && (lf.loginEmail?.errors?.required)"
                        class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> This is
                        required
                      </div>
                      <div *ngIf="loginFormSubmitted && (lf.loginEmail?.errors?.email)"
                        class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i>
                        Invalid email address
                      </div>
                    </div>
                    <div class="form-group position-relative has-icon-left">
                      <input id="txtpassword" type="password" formControlName="loginPassword"
                        class="form-control text-white" placeholder="Password"
                        [ngClass]="{ 'is-invalid': loginFormSubmitted && lf.loginPassword?.invalid }" required>
                      <div class="form-control-position">
                        <i class="text-dark ft-lock"></i>
                      </div>
                      <div class="form-control-position" (click)="changePasswordView()"
                        style="right: 0; cursor: pointer;">
                        <i [ngClass]="passwordBool === true ? 'text-dark fa fa-eye': 'text-dark fa fa-eye-slash'"
                          aria-hidden="true"></i>
                      </div>

                      <div
                        *ngIf="loginFormSubmitted && (lf.loginPassword?.invalid || lf.loginPassword?.errors?.required)"
                        class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> This is
                        required
                      </div>
                    </div>
                    <div class="d-sm-flex justify-content-end mb-2 font-small-2 text-right">
                      <a class="text-white" routerLink="/forgot-password">Forgot Password?</a>
                    </div>
                    <div>
                      <button type="submit" class="btn btn-success w-100 text-dark text-wrap" [disabled]="isLoading">
                        Login
                      </button>
                      <!-- <a [href]="ezportal"> -->
                        <!-- <button type="button" class="btn btn-dark w-100 text-white text-wrap my-2" (click)="back()">
                          Back
                        </button> -->
                      <!-- </a> -->
                    </div>
                  </form>
                </div>
                <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-right p-0">
                  <a href="https://ez.e4score.com/login" target="_blank">
                    <button type="button" class="btn btn-sm btn-success text-dark text-wrap mt-4" style="width:42%;">
                      Legacy Login
                    </button>
                  </a>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
export const environment = {
  production: false,
  APIURL: 'https://e4score-eztrack-api-dev.azurewebsites.net/api/v1/',
  // APIURL: 'https://stagee4scoreapi.azurewebsites.net/api/v1/',
  // APIURL: "https://deve4scoreapi.azurewebsites.net/api/v1/",
  AUTO_REFRESH_TIME_OUT: 300000, //5mint
  AUTO_REFRESH_TIME_OUT_ezSettings: 900000,   //15mint
  eztrackIn: "https://ez.e4score.com/login",
  powerYard: "https://poweryard.e4score.com/login",
  expresSra: "https://expressra.e4score.com/",
  contactUs: "https://www.e4score.com/contact-us/",
  learnMore: "https://www.e4score.com/",
  ezportal: "https://ez.e4score.com",
}

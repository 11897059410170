<div class="">
  <div class="modal-header">
    <header-title class="w-100" [headerTitle]="'Edit Columns'">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </header-title>
  </div>

  <div class="modal-body">
    <!-- <perfect-scrollbar [scrollIndicators]="true"> -->
    <div class="set-height" cdkScrollable>
      <ul cdkDropList class="list-group column-list  example-boundary" (cdkDropListDropped)="drop($event)">
        <li class="list-group-item">
          <div class="custom-control custom-checkbox">
            <input (change)="selectAll($event)" [checked]="isAllSelected" type="checkbox" checked="" name="customCheck"
              id="select-all" class="custom-control-input">
            <label for="select-all" class="custom-control-label">
              <span></span>
              <p title="Select All" class="text-truncate mb-0 font-weight-bold">
                Select All
              </p>
            </label>
          </div>
        </li>
        <!-- [cdkDragDisabled]="!columnItem.isDragAble" -->
        <li class="list-group-item" *ngFor="let columnItem of menuColumns;let i=index" cdkDrag
          cdkDragBoundary=".example-boundary">
          <span class="example-custom-placeholder" *cdkDragPlaceholder></span>
          <div class="d-flex justify-content-between">
            <div class="custom-checkbox custom-control">
              <input (change)="toggleColumn(columnItem)" [checked]="columnItem.isShowColumn" type="checkbox" checked=""
                name="customCheck" [id]="'item'+i" class="custom-control-input">
              <label [for]="'item'+i" class="custom-control-label">
                <p [title]="columnItem.title || columnItem.label || columnItem.field"
                  class="text-truncate mb-0 font-weight-bold">
                  {{ columnItem.label || columnItem.field }}</p>
              </label>
            </div>
            <!-- *ngIf="columnItem.isDragAble" -->
            <div class="example-handle" cdkDragHandle>
              <span>
                <i class="ft-move "></i>
              </span>
            </div>
          </div>
        </li>

      </ul>
    </div>
    <!-- </perfect-scrollbar> -->
  </div>

  <div class="border-top  mt-0 pt-1">
    <button title="Cancel Changes" type="button" class="btn btn-sm btn-danger text-right ml-1" data-dismiss="modal"
      (click)="closeModal()">
      <i class="ft-x"></i> Cancel
    </button>
    <div class="d-flex float-right mb-2 mr-2">
      <button [disabled]="isRestoring" title="Restore to default settings" class="btn btn-sm btn-secondary mr-1" (click)="resetDefault()"
        title="Restore to Default">
        <i class="ft-refresh-cw"></i>
        Restore
      </button>
      <button class="btn btn-sm btn-success" (click)="applyColumn()" title="Save custom settings">
        <i class="ft-check-circle"></i>
        Save
      </button>


    </div>
  </div>
</div>